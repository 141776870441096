import React, { useEffect, useState } from "react";
import { getProductQuantitiesTPA } from "services/thirdpartyapiServices";
import {
  useParams,
  withRouter
} from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";

import { getProductByName, getSlugByProduct } from "services/productServices";
import navigationAction from "redux/navigation/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import Userdetail from "./userdetail";
import Markers from "./markers";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Artwork from "./artwork";
import Papers from "./papers";
import Color from "./color";
import InquiryActions from "redux/InquiryData/actions";
import { Helmet } from "react-helmet";
import { addIPAdd, updateIPAdd } from "services/IPAddServices";
import axios from "axios";
import { getCategoryByName } from "services/categoryServices";
import currentRouteAction from "redux/currentRoute/action";
import productimageAction from "redux/productimage/actions";

const { setAllData, setIpData, setPData, setStep } = InquiryActions;

const { success, error } = navigationAction;
const { currentRoute } = currentRouteAction;
const { image, brandimage } = productimageAction;

function Detail(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { token, inquiry } = props;
  const { step, pData, ipData, allData } = inquiry;
  let pQuantityArray = pData?.pquantity
    ? pData?.pquantity?.split(",")
    : pData?.product_quantity?.split(",");
  const [ans, setAns] = useState(pQuantityArray);
  // const [quanitityState, setQuantityState] = useState(pQuantityArray);

  let { name, slug } = useParams();
  const [product, setProduct] = useState("");

  let isCurrentLocationPrinting = location.pathname.includes(
    "printing-products"
  );
  let newName = name;
  if (name) {
    newName = newName.replace(/_/g, " ");
    name = name.replace(/_/g, " ");
    name = name.replace(/#/g, "/");
    // name = name.replace(/_#_/g, " / ");
  }

  useEffect(() => {
    // dispatch(setAllData([]));

    const getDataByUrl = async () => {
      if (name && slug) {
        let seoSlug = slug.replace(/-/g, " ");
        await getSlugByProduct(token, slug)
          .then(data => {
            console.log(data, "the data", slug, "the slug");
            let finalData = [];
            if (data?.data?.length > 0) {
              finalData = data?.data.filter(
                ({ product_name, slug }) =>
                  product_name
                    ?.trim()
                    ?.toLowerCase()
                    ?.replace(/-/g, " ")
                    .replace(/_/g, " ") ==
                    name
                      ?.trim()
                      ?.toLowerCase()
                      ?.replace(/-/g, " ")
                      ?.replace(/_/g, " ") &&
                  slug
                    ?.trim()
                    ?.toLowerCase()
                    ?.replace(/-/g, " ")
                    .replace(/_/g, " ") ==
                    seoSlug
                      ?.trim()
                      ?.toLowerCase()
                      ?.replace(/-/g, " ")
                      ?.replace(/_/g, " ")
              );

              console.log(finalData, "the finalData");
              if (finalData.length > 0) {
                const newQut = finalData[0]?.product_quantity
                  ? finalData[0]?.product_quantity?.split(",")
                  : finalData[0]?.product_quantity?.split(",");
                newQut.unshift("Select Quantity");
                console.log(finalData, "finalData");
                const productData = {
                  size: finalData[0]?.size,
                  pname: finalData[0]?.product_name,
                  pid: finalData[0]?.id,
                  pquantity: finalData[0]?.product_quantity,
                  description: finalData[0]?.description,
                  SKU: finalData[0]?.SKU
                };
                dispatch(setPData(productData));
                setAns(newQut);
                setProduct(productData);
                dispatch(
                  setAllData({
                    ...allData,
                    cant_find_your_size: "false",
                    category: finalData[0]?.name,
                    size: finalData[0]?.size,
                    quantity: undefined
                  })
                );
                dispatch(
                  setIpData({
                    ...ipData,
                    // IP_Address: "171.50.247.33",
                    completedStep: 1,
                    stepName: "Product Selected",
                    product_id: finalData[0]?.id,
                    category_id: finalData[0]?.category_id
                  })
                );

                dispatch(
                  image({
                    tabName: finalData[0]?.product_name,
                    image_src: finalData[0]?.product_image,
                    isPrinting: isCurrentLocationPrinting || false
                  })
                );
              }
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    };
    getDataByUrl();
    dispatch(setStep(2));
    dispatch(
      setAllData({
        ...allData,
        quantity: undefined
      })
    );
  }, [name, slug]);

  useEffect(() => {
    if (name) {
      dispatch(
        currentRoute({ currentRoute: newName, isCurrentLocationPrinting })
      );
    }
  }, [name, dispatch]);

  const getSKUData = async sku => {
    await getProductQuantitiesTPA({ sku: sku }).then(res => {
      if (res.data) {
        if (res.data.length > 0) {
          setAns(
            res?.data[0] === "Select Quantity"
              ? res?.data
              : ["Select Quantity", ...res.data]
          );
        }
      }
    });
  };

  const getIPData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    dispatch(setIpData({ ...ipData, IP_Address: res.data.IPv4 }));
  };

  const addIPData = async () => {
    await addIPAdd(ipData).then(data => {
      if (data.success) {
        success(data.message);
      } else {
        error(data.message);
      }
    });
  };

  useEffect(() => {
    ans && ans[0] !== "Select Quantity" && setAns(["Select Quantity", ...ans]);
  }, []);

  const UpdateIPData = async () => {
    await updateIPAdd(ipData).then(data => {
      if (data.success) {
        success(data.message);
      } else {
        error(data.message);
      }
    });
  };

  const getProductData = async () => {
    await getProductByName(token, slug, name).then(res => {
      if (res.success) {
        dispatch(setPData(res.data[0]));
        setProduct(res.data[0]);
      } else {
        error(res.message);
      }
    });
  };

  useEffect(() => {
    getIPData();
    if (
      ipData?.IP_Address &&
      ipData.IP_Address !== "" &&
      ipData.completedStep === 1
    ) {
      addIPData();
    }
    // eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    if (
      ipData?.IP_Address &&
      ipData.IP_Address !== "" &&
      ipData.completedStep > 1
    ) {
      UpdateIPData();
    }

    // eslint-disable-next-line
  }, [step, ipData.stepName]);

  useEffect(() => {
    if (product.SKU) {
      getSKUData(product.SKU);
    }
    // eslint-disable-next-line
  }, [product?.SKU]);

  useEffect(() => {
    getProductData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{product.product_name}</title>
        <meta name="robots" content="max-image-preview:large" />
      </Helmet>
      {step === 2 ? (
        <div className="row wanting">
          <div className="col-sm-12">
            {console.log("check144", product)}
            <div className="isCatNameTag">
              <h4 className="p-title">{slug?.replace(/-/g, " ")}</h4>
            </div>
            <h5>
              <span className="mr-2">{product.product_name}</span>
              {product.size}
            </h5>
            <h3 className="sms-title">Get An Instant Price By SMS Now</h3>
            <div className="col-12 col-md-9 my-2 px-0">
              <h4 className="main-title">How many are you wanting ?</h4>
              <select
                className="want_width"
                value={allData.quantity}
                onChange={e => {
                  dispatch(
                    setAllData({
                      ...allData,
                      quantity: e.target.value
                    })
                  );
                }}
              >
                {ans?.map((val, ind) => {
                  return (
                    <option key={ind} value={val}>
                      {val}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="btn-align mb-3">
              <button
                type="button"
                className="btn btn_base_style my-2 mx-1"
                // onClick={() => {
                //   allData?.category === "ENVELOPES"
                //     ? dispatch(setStep(step - 0.5))
                //     : allData?.category === "FLYERS"
                //     ? dispatch(setStep(step - 0.4))
                //     : dispatch(setStep(step - 1));
                //   dispatch(
                //     setIpData({
                //       ...ipData,
                //       completedStep: ipData.completedStep - 1,
                //     })
                //   );
                // }}
                onClick={() => {
                  if (allData.category === "ENVELOPES") {
                    dispatch(setStep(step + 0.5));
                    dispatch(
                      setIpData({
                        ...ipData,
                        completedStep: ipData.completedStep - 1
                      })
                    );
                  } else if (allData.category === "FLYERS") {
                    dispatch(setStep(step + 0.6));
                    dispatch(
                      setIpData({
                        ...ipData,
                        completedStep: ipData.completedStep - 1
                      })
                    );
                  } else {
                    props.history.go(-1);
                    dispatch(setPData([]));
                    dispatch(setAllData([]));
                  }
                }}
              >
                Previous
              </button>
              <button
                type="button"
                className="btn btn_base_style my-2"
                onClick={() => {
                  // allData?.category === "SHOPPING LIST TO DO LISTS"
                  // ? setStep(step - 0.5)
                  // eslint-disable-next-line
                  // if (allData.quantity == undefined) {
                  //   setAllData({
                  //     ...allData,
                  //     quantity: ans[0]
                  //   });
                  // }
                  // dispatch(setStep(step + 1));
                  // dispatch(
                  //   setIpData({
                  //     ...ipData,
                  //     stepName: "Quantity Selected",
                  //     completedStep: ipData.completedStep + 1
                  //   })
                  // );
                  if (allData?.quantity) {
                    if (allData?.quantity == undefined) {
                      setAllData({
                        ...allData,
                        quantity: ans[1]
                      });
                    }
                    dispatch(setStep(step + 1));
                    dispatch(
                      setIpData({
                        ...ipData,
                        stepName: "Quantity Selected",
                        completedStep: ipData.completedStep + 1
                      })
                    );
                  } else {
                    error("Please select quantity");
                  }
                }}
              >
                Next
              </button>
            </div>
            <div className="">
              <span className="cateogoryDescp">
                <div
                  dangerouslySetInnerHTML={{
                    __html: product.description
                  }}
                  className="notes-text"
                />
              </span>
            </div>
          </div>
        </div>
      ) : step === 2.5 ? (
        <Color />
      ) : step === 2.6 ? (
        <Papers />
      ) : step === 3 ? (
        <Artwork />
      ) : step === 3.5 ? (
        <Markers />
      ) : (
        // <GoogleReCaptchaProvider
        //   reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        //   scriptProps={{
        //     async: false,
        //     defer: false,
        //     appendTo: "head",
        //     nonce: undefined,
        //   }}
        // >
        <Userdetail />
        // </GoogleReCaptchaProvider>
      )}
    </>
  );
}
const mapStateToProps = state => {
  console.log("check state 168", state);
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    inquiry: state.inquiry
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { success, error })
)(Detail);
