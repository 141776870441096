import React, { useEffect, useState } from "react";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Helmet } from "react-helmet";
import { getWhyChooseGreen } from "services/settingservices";
import whyChooseGreenHeader from "../../../assets/siteImages/whyChooseGreenHeader.png";
import whyChooseGreenFooter from "../../../assets/siteImages/whyChooseGreenFooter.png";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function WhyChooseGreen(props) {
  const { token } = props; //settingdata in props
  const [artdata, setartdata] = useState([]);
  const getWhyChooseGreenDetails = async () => {
    await getWhyChooseGreen().then(data => {
      if (data.success) {
        setartdata(data.data[0]);
        success();
        // setCategoryOptions(
        //   data.data.map((val) => ({ value: val.id, label: val.name }))
        // );
      } else {
        error(data.message);
      }
    });
  };
  useEffect(() => {
    getWhyChooseGreenDetails();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Helmet>
        <title>Why Choose Green – Wholesale Magnets NZ</title>
      </Helmet>
      {artdata?.why_choose_green_image && (
        <div className="apus-breadscrumb1">
          <img
            // src="http://wholesale-magnets.com.au/wp-content/uploads/2020/07/rsz_11wholesale_magnets_website-05_2-2.png"
            // src={whyChooseGreenHeader}
            src={
              artdata?.why_choose_green_image
                ? `${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${artdata.why_choose_green_image}`
                : whyChooseGreenHeader
            }
            alt=""
          />
        </div>
      )}
      <div className="container">
        <div className="row about my-4 px-3">
          <div
            dangerouslySetInnerHTML={{
              __html: artdata?.why_choose_green
            }}
            className="notes-text"
          />
          {/*
          
          <p>
            Calendar fridge magnets are a huge part of Wholesale Magnets and
            they guarantee to support their network of loyal customers by
            continuing to supply the Thickest, Strongest & Cheapest fridge
            magnets on the market.
          </p>
          <p>
            All magnets are individually wrapped at no additional charge (except
            business cards), protecting your investment from rain and dust, and
            also giving the magnet a higher perceived value with the homeowners,
            giving you a much higher chance of getting your face on the fridge.
            After all, aren’t all gifts wrapped?
          </p>
          <p>
            Make no mistake, if you are buying your fridge magnets anywhere
            else, you’re paying too much.
          </p>
          <p>
            Use this site to get an instant price on your next magnet campaign
            or if you prefer, simply call us on
            <strong>
              {" "}
              <a
                href={`tel:${settingdata?.phone_no}`}
                style={{ color: "#000", fontSize: "17px" }}
              >
                {`${settingdata?.phone_no?.substring(
                  0,
                  4
                )} ${settingdata?.phone_no?.substring(
                  4,
                  7
                )} ${settingdata?.phone_no?.substring(7, 10)}`}
              </a>{" "}
            </strong>
            to discuss.
          </p> */}
          {/* <div className="apus-breadscrumb1">
            <img
              // src="http://wholesale-magnets.com.au/wp-content/uploads/2020/07/rsz_11wholesale_magnets_website-05_2-2.png"
              src={whyChooseGreenFooter}
              alt=""
            />
          </div> */}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
    // settingdata: state.settingdata.sdata
  };
};

export default compose(
  withRouter,
  // enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(WhyChooseGreen);
