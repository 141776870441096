// const productimageAction = {
//   IMAGE: "IMAGE",

//   image: (data) => {
//     console.log("image", data);
//     return {
//       type: productimageAction.IMAGE,
//       payload: data,
//     };
//   },
// };

// export default productimageAction;

const productimageAction = {
  IMAGE: "IMAGE",
  RESET: "RESET",

  image: data => {
    console.log("image", data);
    return {
      type: productimageAction.IMAGE,
      payload: data
    };
  },
  resetImage: () => {
    return {
      type: productimageAction.RESET
    };
  }
};

export default productimageAction;
