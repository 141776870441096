import React, { useState } from "react";
import { getMagSmsServer2 } from "services/thirdpartyapiServices";

const TestSMS = () => {
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    await getMagSmsServer2().then(res => {
      setResponse(res.data);
    });
    setLoading(false);
  };

  return (
    <>
      <button onClick={handleSubmit}>
        {loading ? "Loading....." : "Clicked"}
      </button>

      <div>Response :-{response?.data}</div>
      <div>Payload URL:-{response?.url}</div>
    </>
  );
};

export default TestSMS;
