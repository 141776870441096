const InquiryActions = {
  ALLDATA: "allData",
  STEP: "step",
  PDATA: "pdata",
  IPDATA: "ipdata",

  setAllData: data => {
    console.log("data check8", data);
    return {
      type: InquiryActions.ALLDATA,
      payload: data
    };
  },
  setStep: data => {
    return {
      type: InquiryActions.STEP,
      payload: data
    };
  },
  setPData: data => {
    return {
      type: InquiryActions.PDATA,
      payload: data
    };
  },
  setIpData: data => {
    return {
      type: InquiryActions.IPDATA,
      payload: data
    };
  }
};
export default InquiryActions;
