import InquiryActions from "./actions";

const initialState = {
  step: 1,
  pData: [],
  ipData: {
    IP_Address: "",
    completedStep: 0,
    stepName: "",
    product_id: ""
  },
  allData: []
};
export default function InquiryReducer(state = initialState, action) {
  switch (action.type) {
    case InquiryActions.STEP:
      return {
        ...state,
        step: action.payload
      };
    case InquiryActions.ALLDATA:
      console.log("came here first time", action.payload);
      return {
        ...state,
        allData: action.payload
      };
    case InquiryActions.PDATA:
      return {
        ...state,
        pData: action.payload
      };
    case InquiryActions.IPDATA:
      return {
        ...state,
        ipData: action.payload
      };
    default: {
      return state;
    }
  }
}
