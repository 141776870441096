import React, { useState } from "react";
import { useEffect } from "react";
// import { getColors } from "services/colorServices";
import { getProductsByColorSKU } from "services/productServices";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  useParams,
  withRouter
} from "react-router-dom/cjs/react-router-dom.min";
import InquiryActions from "redux/InquiryData/actions";
import navigationAction from "redux/navigation/actions";
import { useDispatch } from "react-redux";
const { success, error } = navigationAction;
const { setAllData, setIpData, setPData, setStep } = InquiryActions;

function Color(props) {
  const { success, error, token, inquiry } = props;
  const { step, pData, ipData, allData } = inquiry;
  const dispatch = useDispatch();
  let { slug } = useParams();

  // const ans = pData?.pquantity?.split(",");
  const [color, setColor] = useState();

  const ID_data = { id: pData.pid ? pData.pid : pData.id };
  console.log("allData", allData, step);

  const getcolors = async () => {
    await getProductsByColorSKU(token, ID_data).then(data => {
      if (data.success) {
        setColor(data.data);
        // uncomment below line for local server
        // setColor([{ color: "red", label: "red" }]);
        success();
      } else {
        error(data.message);
      }
    });
  };

  //USEEFFECTS

  useEffect(() => {
    getcolors();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row wanting">
        <div className="col-sm-12">
          <div className="isCatNameTag">
            <h4 className="p-title">{slug?.replace(/-/g, " ")}</h4>
          </div>
          <h5>
            <span className="mr-2">{pData.product_name}</span>
            {pData.size}
          </h5>
          <h3 className="sms-title">Get An Instant Price By SMS Now</h3>
          <div className="col-12 col-md-12 my-2 px-0">
            <h4 className="main-title">
              How Many Colours Would You Like To Print In ?
            </h4>
          </div>
          <div className="col-12 col-md-9 my-2 px-0">
            {console.log("pData", pData)}
            <select
              className="want_width"
              value={allData.color}
              onChange={e => {
                dispatch(setAllData({ ...allData, color: e.target.value }));

                const data = color.find(val => val.color === e.target.value);
                pData.SKU = data.Product_SKU;
              }}
            >
              {color?.map(val => {
                return <option value={val.color}>{val.color}</option>;
              })}
            </select>
          </div>
          <div className="btn-align mb-3">
            <button
              type="button"
              className="btn btn_base_style my-2 mx-1"
              onClick={() => {
                dispatch(setStep(step - 1.5));
                dispatch(
                  setIpData({
                    ...ipData,
                    completedStep: ipData.completedStep - 1
                  })
                );
                props.history.go(-1);

                //stepName:"Color Selected"
              }}
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn_base_style my-2"
              onClick={() => {
                // eslint-disable-next-line
                console.log("step check 100", step);
                if (allData.color == undefined) {
                  dispatch(setAllData({ ...allData, color: color[0].color }));

                  pData.SKU = color[0].Product_SKU;
                }
                dispatch(setStep(step - 0.5));
                dispatch(
                  setIpData({
                    ...ipData,
                    stepName: "Color Selected",
                    completedStep: ipData.completedStep + 1
                  })
                );
              }}
            >
              Next
            </button>
          </div>
          <div className="">
            <span className="cateogoryDescp">
              <div
                dangerouslySetInnerHTML={{
                  __html: pData.description
                }}
                className="notes-text"
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {
  console.log("check state 168", state);
  return {
    ...state.themeChanger,
    token: state.auth.accessToken, //!= null? state.auth.accessToken : state.auth.token,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    inquiry: state.inquiry
  };
};
// const mapDispatchToProps = () => {};

export default compose(
  withRouter,
  // enhancer,
  connect(
    mapStateToProps,
    { success, error }
    // mapDispatchToProps
  )
)(Color);
