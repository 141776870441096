import React, { useEffect, useState } from "react";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Helmet } from "react-helmet";
import { getAboutus } from "services/settingservices";
import aboutusHeader from "../../../assets/siteImages/AboutUsHeader.png";
import aboutusFooter from "../../../assets/siteImages/AboutUsFooter.png";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function AboutUs(props) {
  const { token } = props; //settingdata in props
  const [artdata, setartdata] = useState([]);
  const getAboutUs = async () => {
    await getAboutus(token).then(data => {
      if (data.success) {
        setartdata(data.data[0]);
        success();
        // setCategoryOptions(
        //   data.data.map((val) => ({ value: val.id, label: val.name }))
        // );
      } else {
        error(data.message);
      }
    });
  };
  useEffect(() => {
    getAboutUs();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Helmet>
        <title>About – Wholesale Magnets NZ</title>
      </Helmet>
      <div className="apus-breadscrumb1">
        <img
          // src="http://wholesale-magnets.com.au/wp-content/uploads/2020/07/rsz_11wholesale_magnets_website-05_2-2.png"
          src={aboutusHeader}
          alt=""
        />
      </div>
      <div className="container">
        <div className="row about my-4 px-3">
          <div
            dangerouslySetInnerHTML={{
              __html: artdata?.aboutus
            }}
            className="notes-text"
          />
          {/*
          Introducing The World’s First Sustainable Fridge Magnets

Since 1994, Clever Fridge Magnets has been at the forefront of the promotional magnets industry in Australia. Today, we are excited to introduce Green Magnets, a pioneering step towards sustainability in magnet production. At Green Magnets, our commitment to the environment is reflected in every aspect of our manufacturing process.

Our Sydney-based factory operates on 90% solar energy, minimizing our carbon footprint. We’ve chosen to use only plastic-free, biodegradable laminate and recycled paper in our products, ensuring that our magnets are not only beautiful but also environmentally friendly. Additionally, our magnet sheets are made with at least 30% recycled materials, further reducing our environmental impact.

Green Magnets represents our dedication to innovation and sustainability. We are proud to offer these eco-friendly alternatives to our customers, helping them make greener choices without compromising on quality. Join us in our journey towards a more sustainable future, one magnet at a time.
          <p>
            Calendar fridge magnets are a huge part of Wholesale Magnets and
            they guarantee to support their network of loyal customers by
            continuing to supply the Thickest, Strongest & Cheapest fridge
            magnets on the market.
          </p>
          <p>
            All magnets are individually wrapped at no additional charge (except
            business cards), protecting your investment from rain and dust, and
            also giving the magnet a higher perceived value with the homeowners,
            giving you a much higher chance of getting your face on the fridge.
            After all, aren’t all gifts wrapped?
          </p>
          <p>
            Make no mistake, if you are buying your fridge magnets anywhere
            else, you’re paying too much.
          </p>
          <p>
            Use this site to get an instant price on your next magnet campaign
            or if you prefer, simply call us on
            <strong>
              {" "}
              <a
                href={`tel:${settingdata?.phone_no}`}
                style={{ color: "#000", fontSize: "17px" }}
              >
                {`${settingdata?.phone_no?.substring(
                  0,
                  4
                )} ${settingdata?.phone_no?.substring(
                  4,
                  7
                )} ${settingdata?.phone_no?.substring(7, 10)}`}
              </a>{" "}
            </strong>
            to discuss.
          </p> */}
          <div className="apus-breadscrumb1">
            <img
              // src="http://wholesale-magnets.com.au/wp-content/uploads/2020/07/rsz_11wholesale_magnets_website-05_2-2.png"
              src={aboutusFooter}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
    // settingdata: state.settingdata.sdata
  };
};

export default compose(
  withRouter,
  // enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(AboutUs);
