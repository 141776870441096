import React, { useEffect, useRef, useState } from "react";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import InquiryActions from "redux/InquiryData/actions";

import AuthActions from "redux/auth/actions";
import Userdetail from "./userdetail";
import Markers from "./markers";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";

const { setAllData, setIpData, setPData, setStep } = InquiryActions;
const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function Size(props) {
  // const {
  //   token,
  //   success,
  //   fetching,
  //   isFetching,
  //   error,
  //   setFieldValue,
  //   values,
  //   handleChange,
  //   handleSubmit,
  //   setValues,
  //   isValid,
  //   handleBlur,
  //   errors,
  //   touched,
  //   submitCount,
  // } = props;
  const { inquiry } = props;
  const { step, allData } = inquiry;
  const dispatch = useDispatch();
  // const [step, setStep] = useState(1);
  // const allDataState = useState([
  // {
  //   quntity: "",
  //   artwork: "",
  //   name: "",
  //   company: "",
  //   mobile_no: "",
  //   delivery_postcode: "",
  //   email: "",
  // },
  // ]);
  console.log(process.env.REACT_APP_RECAPTCHA_SITE_KEY, "site key");
  useEffect(() => {
    dispatch(
      setAllData({
        category: "Small Business Card 70 by 50mm"
      })
    );
    //eslint-disable-next-line
  }, []);

  // const scrollRef = useRef();

  // const scrolltotop=()=>{

  // }
  const mainRef = useRef();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [step]);

  return (
    <>
      <div className="row" ref={mainRef}>
        <div className="col-12">
          <div className="title">
            <h3>Get An Instant Price By SMS Now</h3>
            <h4>
              What fridge magnet are you looking for ? <span> Click Below</span>
            </h4>
          </div>
        </div>
      </div>
      {step === 1 ? (
        <>
          <div className="row">
            <div className="col-12">
              <h3 className="size_h3">Looking for something different ?</h3>
            </div>
            <div className="col-12">
              <h4 className="size_h4">What Size are you looking for ?</h4>
            </div>
          </div>
          <div className="row size_margin">
            <div className="col-12 pl-0">
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r1">
                  <input
                    id="r1"
                    type="radio"
                    name="r1"
                    checked={
                      allData?.category === "Small Business Card 70 by 50mm"
                        ? true
                        : false
                    }
                    className="mr-2"
                    onChange={e => {
                      dispatch(
                        setAllData({
                          category: "Small Business Card 70 by 50mm"
                        })
                      );
                    }}
                  />
                  Small Business Card 70 by 50mm
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r2">
                  <input
                    id="r2"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(
                        setAllData({ category: "A6 magnet 148mm by 105mm" })
                      );
                    }}
                  />
                  A6 magnet 148mm by 105mm
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r3">
                  <input
                    id="r3"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(
                        setAllData({ category: "DL magnet 210 by 97mm" })
                      );
                    }}
                  />
                  DL magnet 210 by 97mm
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r4">
                  <input
                    id="r4"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(setAllData({ category: "DLS 168mm by 78.5mm" }));
                    }}
                  />
                  DLS 168mm by 78.5mm
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r5">
                  <input
                    id="r5"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(
                        setAllData({
                          category: "Business Card Magnet 90mm by 55mm"
                        })
                      );
                    }}
                  />
                  Business Card Magnet 90mm by 55mm
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r6">
                  <input
                    id="r6"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(setAllData({ category: "Car" }));
                    }}
                  />
                  Car
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r7">
                  <input
                    id="r7"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(setAllData({ category: "House" }));
                    }}
                  />
                  House
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r8">
                  <input
                    id="r8"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(setAllData({ category: "Light Bulb" }));
                    }}
                  />
                  Light Bulb
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r9">
                  <input
                    id="r9"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(setAllData({ category: "Truck" }));
                    }}
                  />
                  Truck
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r10">
                  <input
                    id="r10"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(setAllData({ category: "Van" }));
                    }}
                  />
                  Van
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r11">
                  <input
                    id="r11"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(setAllData({ category: "Tooth" }));
                    }}
                  />
                  Tooth
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r12">
                  <input
                    id="r12"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(setAllData({ category: "Love Heart" }));
                    }}
                  />
                  Love Heart
                </label>
              </div>
              <div className="label" style={{ cursor: "pointer" }}>
                <label htmlFor="r13">
                  <input
                    id="r13"
                    type="radio"
                    name="r1"
                    className="mr-2"
                    onChange={e => {
                      dispatch(setAllData({ category: "Others" }));
                    }}
                  />
                  Others
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h4 className="main-title">
                Still can't find what you are looking for ?
              </h4>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-7">
              <textarea
                className="form-control"
                onChange={e => {
                  dispatch(
                    setAllData({ ...allData, customer_notes: e.target.value })
                  );
                }}
                style={{ padding: "7px 4px", fontSize: "17px" }}
                placeholder="Explain to us what you are looking for?"
              ></textarea>
            </div>
          </div>
          <div className="row wanting">
            <div className="col-11">
              {/* <div className='my-2'> */}
              <h4 className="main-title">How many are you wanting ?</h4>
              <select
                // style={{ width: "450px" }}
                value={allData.quantity}
                onChange={e => {
                  // onhandlechange(e);
                  dispatch(
                    setAllData({ ...allData, quantity: e.target.value })
                  );
                }}
              >
                <option value={500}>Select Quantity</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
                <option value={1500}>1500</option>
                <option value={2000}>2000</option>
                <option value={2500}>2500</option>
                {/* {product.map((val) => {
          return <option>{val.product_quantity}</option>;
        })} */}
              </select>
              {/* </div> */}
            </div>
            <div className="col-9 col-sm-9">
              <div className="wanting_btn mb-3">
                <button
                  type="button"
                  className="btn btn_base_style my-3"
                  onClick={() => {
                    props.history.push("/");
                  }}
                >
                  Previous
                </button>
                <button
                  type="button"
                  className="btn btn_base_style my-3 mx-1 "
                  onClick={() => {
                    dispatch(setStep(step + 1));
                    if (allData.quantity === undefined) {
                      dispatch(
                        setAllData({
                          ...allData,
                          quantity: "500",
                          cant_find_your_size: "true"
                        })
                      );
                    } else {
                      dispatch(
                        setAllData({ ...allData, cant_find_your_size: "true" })
                      );
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      ) : step === 2 ? (
        <Markers />
      ) : (
        // <GoogleReCaptchaProvider
        //   reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        //   scriptProps={{
        //     async: false,
        //     defer: false,
        //     appendTo: "head",
        //     nonce: undefined
        //   }}
        // >
        <Userdetail />
        // </GoogleReCaptchaProvider>
      )}
    </>
  );
}

const mapStateToProps = state => {
  console.log("check state 168", state);
  return {
    ...state.themeChanger,
    token: state.auth.accessToken, //!= null? state.auth.accessToken : state.auth.token,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    inquiry: state.inquiry
  };
};

export default compose(
  withRouter,
  // enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(Size);
