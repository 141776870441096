import { withFormik } from "formik";
import * as Yup from "yup";

import "yup-phone-lite";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Please Enter Name")
      .matches(/^[a-zA-Z ]*$/, "Please Enter valid Name"),
    company: Yup.string()
      .required("Please Enter Company Name")
      .matches(/^[a-zA-Z ]*$/, "Please Enter valid Company Name"),
    // mobile_no: Yup.string()
    //   .required("Please Enter Mobile Number")
    //   .matches(
    //     `/^(\+64|0)2\d{7,9}$/`,
    //     // /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
    //     // /^(?:\+?(61))??(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[-](?=\d{3})|(?!\d\d[-]?\d[-]))\d\d[-]?\d[-]?\d{3})$/,
    //     // /^\+?(61)?[-.\s]?\d{1}?[-.\s]?\d{4}[-.\s]?\d{4}$/,
    //     "Please Enter Valid Mobile Number"
    // ),
    mobile_no: Yup.string()
      .matches(
        /^02\d{7,9}$/,
        "Enter a valid NZ number starting with '02' and 7-11 digits long"
      )
      .required("Please enter a valid mobile number"),

    // mobile_no: Yup.string()
    //   .phone("NZ", "Please enter a valid phone number")
    //   .required("Please Enter Valid Mobile Number"),

    email: Yup.string()
      .required("Please Enter Email")
      .email("Please Enter Valid Email")
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    name: "",
    company: "",
    mobile_no: "",
    delivery_postcode: "",
    email: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
