import { api, handleResponse, handleError } from "./apiServices";

export const getFAQs = token =>
  api(token)
    .get("/faqs/list")
    .then(handleResponse)
    .catch(handleError);

export const getFAQTypes = token =>
  api(token)
    .get("/faqs/types")
    .then(handleResponse)
    .catch(handleError);

// export const updateColor = (token, data) =>
//   api(token)
//     .put("/colors/update", data)
//     .then(handleResponse)
//     .catch(handleError);

// export const deleteColor = (token, data) =>
//   api(token)
//     .delete("/colors/delete", { data: data })
//     .then(handleResponse)
//     .catch(handleError);
