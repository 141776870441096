import currentRouteAction from "./action";

const initState = {
  currentRoute: "",
  isCurrentLocationPrinting: false
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case currentRouteAction.CURRENT_ROUTE:
      return {
        ...state,
        currentRoute: action.payload.currentRoute,
        isCurrentLocationPrinting: action.payload.isCurrentLocationPrinting
      };
    default:
      return state;
  }
}
