import { api, handleResponse, handleError } from "./apiServices";

export const getNews = token =>
  api(token)
    .get("/news/list")
    .then(handleResponse)
    .catch(handleError);
export const getNewsById = title =>
  api()
    .get(`/news/${title}`)
    .then(handleResponse)
    .catch(handleError);
