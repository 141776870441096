import React, { useEffect, useState } from "react";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Helmet } from "react-helmet";
import { getartwork } from "services/artworkServices";
import Header from "../../../assets/siteImages/Header.jpg";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function Artworks(props) {
  const { token, success, error } = props;
  const [artdata, setartdata] = useState({ Artwork: [] });

  const getartworks = async () => {
    await getartwork(token).then(data => {
      if (data.success) {
        const artVal = data.data;
        setartdata(artVal);
        success();
      } else {
        error(data.message);
      }
    });
  };
  useEffect(() => {
    getartworks();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Helmet>
        <title>Artworks – Wholesale Magnets NZ</title>
      </Helmet>
      <div className="apus-breadscrumb1">
        <img
          // src="http://wholesale-magnets.com.au/wp-content/uploads/2020/07/WM_PRINT_banner-1-scaled.jpg"
          src={Header}
          alt=""
        />
      </div>
      <div className="container">
        <div className="row about px-0 mb-3">
          <div className="col-12">
            <div
              dangerouslySetInnerHTML={{
                __html: artdata?.description
              }}
              className="notes-text"
            />
          </div>
        </div>
        <div className="row px-0">
          {artdata?.Artwork.map(val => (
            <>
              <div className="col-md-4">
                <div className="card aw_border card_margin">
                  <div className="img_width">
                    <a
                      href={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val.file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={val.ArtworkName}
                    >
                      <img
                        loading="lazy"
                        src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val.ArtworkImg}`}
                        alt=""
                        sizes="(max-width: 1024px) 100vw, 1024px"
                        className="aw_images"
                      />
                    </a>
                  </div>
                  <p className="pt-3">
                    <a
                      className="aw_cardlink"
                      href={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val.file}`}
                      download={val.ArtworkName}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {}
                      {val.ArtworkName}
                    </a>
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    settingdata: state.settingdata.sdata
  };
};

export default compose(
  withRouter,
  // enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(Artworks);
