import React, { useEffect, useState } from "react";
import {
  getCategories,
  getPrintingCategories
} from "services/categoryServices";
import NavigationActions from "redux/navigation/actions";

import { withRouter, useHistory, Link } from "react-router-dom";
import { compose } from "redux";
// import { connect } from "react-redux";
import { connect, useDispatch } from "react-redux";
import { getPrinting } from "services/productServices";
import currentRouteAction from "redux/currentRoute/action";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const { success, error, fetching, scroll } = NavigationActions;
const { currentRoute } = currentRouteAction;

function PrintingCategories(props) {
  const location = useLocation();
  const { success, error } = props;

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (location.pathname) {
  //     dispatch(currentRoute(location.pathname));
  //   }
  // }, [location.pathname, dispatch]);

  const history = useHistory();
  const [prtcat, setPrtCat] = useState([]);

  // const getCategory = async () => {
  //   await getPrintingCategories().then(data => {
  //     if (data.success) {
  //       console.log(data.data, "check 19");

  // useEffect(() => {
  //   if (location.pathname) {
  //     dispatch(currentRoute(location.pathname));
  //   }
  // }, [location.pathname, dispatch]);

  // const history = useHistory();
  // const [prtcat, setPrtCat] = useState([]);

  const getCategory = async () => {
    await getPrintingCategories().then(data => {
      if (data.success) {
        console.log(data.data, "check 19");

        setPrtCat(data.data);

        success();
      } else {
        error(data.message);
      }
    });
  };

  //USEEFFECT

  useEffect(() => {
    getCategory();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="title">
            <h3>Get An Instant Price By SMS Now</h3>
            <h4>
              What printing product are you looking for ?{" "}
              <span> Click Below</span>
              {/* size
                {!history.location.pathname.includes("/printing-products")
                  ? "fridge magnet"
                  : " "} */}
            </h4>
          </div>
        </div>
        {prtcat.map((val, ind) => {
          return (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
                <div
                  className="card "
                  key={ind}
                  onClick={() => {
                    history.push(
                      `/printing-products/${val.name
                        .replaceAll(" ", "-")
                        .toLowerCase()}`
                    );
                  }}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <div className="Printing_img">
                    {/* style={{
                    maxHeight: "280px",
                    width: "100%",
                    objectFit: "cover"
                  }} */}
                    <img
                      className="card-img-top cat_img"
                      src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.category_image}`}
                      alt="Card cap"
                      //   width="100%"
                    />
                  </div>
                  {/* <div className="prdsize">
                              <p>{val.size}</p>
                            </div> */}
                  <div className="card-body  text-center img_txt bg-color-secondary">
                    <h5
                      className="card-title"
                      key={ind}
                      style={{ marginTop: "0.75rem" }}
                    >
                      {val.name}
                    </h5>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        <div className="col-12 my-2">
          <span
            style={{
              fontSize: "17px",
              fontWeight: 500,
              textDecoration: "Underline"
            }}
            className="color-secondary"
          >
            <Link to="/contact">Cant find what you’re looking for?</Link>
          </span>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, { success, error, fetching, scroll })
)(PrintingCategories);
