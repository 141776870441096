import React, { useEffect, useState } from "react";
// import { getPapers } from "services/paperServices";
import { getProductsByPaperSKU } from "services/productServices";
import InquiryActions from "redux/InquiryData/actions";
import navigationAction from "redux/navigation/actions";
import { compose } from "redux";
import {
  useParams,
  withRouter
} from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
const { success, error } = navigationAction;
const { setAllData, setIpData, setPData, setStep } = InquiryActions;

function Papers(props) {
  const { success, error, token, inquiry } = props;
  const { step, pData, ipData, allData } = inquiry;
  const dispatch = useDispatch();
  let { slug } = useParams();

  //   const ans = pData?.pquantity?.split(",");
  const [paper, setPaper] = useState([]);
  const [data, setData] = useState([]);
  console.log("pData check 21", pData);
  // const [paper_val, setPaper_val] = useState(paper[0]);

  const ID_data = { id: pData.pid ? pData.pid : pData.id };

  const getPaper = async () => {
    await getProductsByPaperSKU(token, ID_data).then(data => {
      if (data.success) {
        setData(data.data);
        setPaper(data.data);
        //for local server purpose uncomment below line and comment above line
        // setPaper[{ paper: "Standard 250 gsm" }, { paper: "Premium 250 gsm" }];
        success();
      } else {
        error(data.message);
      }
    });
  };

  pData["paperData"] = data;

  //USEEFFECT

  useEffect(() => {
    getPaper();
    //eslint-disable-next-line
  }, []);

  // useEffect(()=>{

  // },[paper])
  return (
    <>
      <div className="row wanting">
        <div className="col-sm-12">
          <div className="isCatNameTag">
            <h4 className="p-title">{slug?.replace(/-/g, " ")}</h4>
          </div>
          <h5>
            <span className="mr-2">{pData.product_name}</span>
            {pData.size}
          </h5>
          <h3 className="sms-title">Get An Instant Price By SMS Now</h3>
          <div className="col-12 col-md-9 my-2 px-0">
            {/* <div className="col-lg-4 col-md-6 col-sm-10"> */}
            <div className="my-2">
              <h4 className="art">Choice of Paper ?</h4>
              {paper.map((val, ind) => {
                return (
                  <div className="label">
                    <label htmlFor={val.paper} style={{ cursor: "pointer" }}>
                      <input
                        checked={
                          allData.paper_choice === val.paper ||
                          (allData.paper_choice === undefined && ind === 0)
                            ? true
                            : false
                        }
                        id={val.paper}
                        type="radio"
                        name="r1"
                        className="mr-1"
                        onChange={e => {
                          dispatch(
                            setAllData({
                              ...allData,
                              paper_choice: val.paper,

                              color: ""
                            })
                          );

                          pData.SKU = val.Product_SKU;
                        }}
                      />
                      {val.paper}
                    </label>
                  </div>
                );
              })}
              {/* <div className="label">
                <label htmlFor="premium_paper">
                  <input
                    checked={
                      paper === "Premium 250gsm" ||
                      allData.paper === "Premium 250gsm"
                        ? true
                        : false
                    }
                    id="premium_paper"
                    type="radio"
                    name="r1"
                    className="mr-1"
                    onChange={(e) => {
                      setPaper(e.target.value);
                    }}
                  />
                
                  Premium 250gsm
                </label>
              </div> */}
            </div>
            <div className="btn-align mb-3">
              <button
                type="button"
                className="btn btn_base_style my-2 mx-1"
                onClick={() => {
                  // dispatch(setStep(1));
                  props.history.go(-1);
                  // dispatch(
                  //   setIpData({
                  //     ...ipData,
                  //     completedStep: ipData.completedStep - 1,
                  //   })
                  // );
                }}
              >
                Previous
              </button>
              <button
                type="button"
                className="btn btn_base_style my-2"
                onClick={() => {
                  dispatch(setStep(step - 0.6));
                  dispatch(
                    setIpData({
                      ...ipData,
                      stepName: "Paper Selected",
                      completedStep: ipData.completedStep + 1
                    })
                  );

                  if (
                    allData.paper_choice === undefined ||
                    allData.paper_choice === ""
                  ) {
                    dispatch(
                      setAllData({ ...allData, paper_choice: paper[0].paper })
                    );
                    pData.SKU = paper[0].Product_SKU;
                  }
                  // else {
                  // setAllData({ ...allData, paper: paper });
                  // }
                }}
              >
                Next
              </button>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="btn-align mb-3">
            <button
              type="button"
              className="btn btn_base_style my-2 mx-1"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn_base_style my-2"
              onClick={() => {
                // eslint-disable-next-line
                if (allData.quantity == undefined) {
                  setAllData({ ...allData, quantity: ans[0] });
                }
                setStep(step + 0.5);
              }}
            >
              Next
            </button>
          </div> */}
          <div className="">
            <span className="cateogoryDescp">
              <div
                dangerouslySetInnerHTML={{
                  __html: pData.description
                }}
                className="notes-text"
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {
  console.log("check state 168", state);
  return {
    ...state.themeChanger,
    token: state.auth.accessToken, //!= null? state.auth.accessToken : state.auth.token,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    inquiry: state.inquiry
  };
};
// const mapDispatchToProps = () => {};

export default compose(
  withRouter,
  // enhancer,
  connect(
    mapStateToProps,
    { success, error }
    // mapDispatchToProps
  )
)(Papers);
