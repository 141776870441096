import { api, handleResponse, handleError } from "./apiServices";

export const getCounts = token =>
  api(token)
    .get("/counts/list")
    .then(handleResponse)
    .catch(handleError);

export const addCount = data =>
  api()
    .post("/counts/add", data)
    .then(handleResponse)
    .catch(handleError);

export const updateCount = data =>
  api()
    .put("/counts/update", data)
    .then(handleResponse)
    .catch(handleError);

export const deleteCount = (token, data) =>
  api(token)
    .delete("/counts/delete", { data: data })
    .then(handleResponse)
    .catch(handleError);
