import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { getCategories, getCategoryByName } from "services/categoryServices";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import {
  getBrandByProduct,
  getBrandByProduct1,
  getSlugByProduct,
  getSlugByProduct1
} from "services/productServices";
import { addIPAdd, updateIPAdd } from "services/IPAddServices";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Detail from "./detail";
import Artwork from "./artwork";
import Userdetail from "./userdetail";
import productimageAction from "redux/productimage/actions";
import Color from "./color";
import Papers from "./papers";
import Markers from "./markers";
import axios from "axios";
import { Helmet } from "react-helmet";

const { success, error, fetching, scroll } = NavigationActions;

const { image, brandimage } = productimageAction;

function Content(props) {
  const { token, success, error, scroll } = props;
  const dispatch = useDispatch();

  const { slug, brand } = useParams();
  console.log(slug, brand, "the slug and brand");
  const newRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState("");
  console.log(process.env.REACT_APP_RECAPTCHA_SITE_KEY, "site key");

  // const [printingarr, setPrintingarr] = useState([]);
  const [productBrand, setProductBrand] = useState([]);
  const [step, setStep] = useState(1);
  const [pdata, setPdata] = useState([]);
  const [alldata, setAlldata] = useState([]);
  const [IPData, setIPData] = useState({
    IP_Address: "",
    completedStep: 0,
    stepName: "",
    product_id: ""
  });
  const getCategoryBySlug = async seoSlug => {
    setCategory("");
    await getCategoryByName(token, seoSlug).then(res => {
      if (res.success) {
        setCategory(res?.data[0]);
        console.log("res dtaa", res.data);
      }
    });
  };

  //creating function to load ip address from the API
  const getIPData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIPData({ ...IPData, IP_Address: res.data.IPv4 });
  };

  const addIPData = async () => {
    await addIPAdd(IPData).then(data => {
      if (data.success) {
        success(data.message);
      } else {
        error(data.message);
      }
    });
  };

  console.log("pdata", pdata);
  console.log("pdataalldata", alldata);

  const UpdateIPData = async () => {
    await updateIPAdd(IPData).then(data => {
      if (data.success) {
        success(data.message);
      } else {
        error(data.message);
      }
    });
  };

  const getData = async () => {
    var sub_cat = "";

    await getCategories(token).then(data => {
      if (!history.location.pathname.includes("/printing-products")) {
        const subcat = data.data?.filter(val => {
          return val?.parent_id !== 0;
        });
        sub_cat = subcat[0]?.slug;
      } else {
        const subcat = data.data?.filter(val => {
          return val?.parent_id === 2;
        });
        sub_cat = subcat[0]?.slug;
      }

      if (data.success) {
        success();
      } else {
        error();
      }
    });
    if (slug === "" || slug === undefined) {
      if (!history.location.pathname.includes("/printing-products")) {
        await getSlugByProduct(token).then(data => {
          if (data) {
            const arrayUniqueByKey = [
              ...new Map(data.data.map(item => [item["id"], item])).values()
            ];

            setProduct(arrayUniqueByKey);
          } else {
            error(data.message);
          }
        });
      } else {
        await getSlugByProduct1(token, slug).then(data => {
          if (data.success) {
            const arrayUniqueByKey = [
              ...new Map(
                data.data.map(item => [item["product_name"], item])
              ).values()
            ];
            setProduct(arrayUniqueByKey);
            // setProduct(data.data);
            success();
          } else {
            error();
          }
        });
      }
    } else {
      if (!history.location.pathname.includes("/printing-products")) {
        await getSlugByProduct(token, slug).then(data => {
          if (data.success) {
            const arrayUniqueByKey = [
              ...new Map(
                data.data.map(item => [item["product_name"], item])
              ).values()
            ];
            setProduct(arrayUniqueByKey);
            success();
          } else {
            error();
          }
        });
        if (slug && brand) {
          await getBrandByProduct(token, slug, brand).then(data => {
            if (data.success) {
              setProductBrand(data.data);
              success();
            } else {
              error();
            }
          });
        }
      } else {
        await getSlugByProduct1(token, slug).then(data => {
          if (data.success) {
            const arrayUniqueByKey = [
              ...new Map(
                data.data.map(item => [item["product_name"], item])
              ).values()
            ];
            setProduct(arrayUniqueByKey);
            // setProduct(data.data);
            success();
          } else {
            error();
          }
        });
        if (slug && brand) {
          await getBrandByProduct1(token, slug, brand).then(data => {
            if (data.success) {
              setProductBrand(data.data);
              success();
            } else {
              error();
            }
          });
        }
      }
    }
  };

  console.log("stepsdsdsdsdasdasdasdas", step);

  // USEEFFECT

  useEffect(() => {
    getData();
    setStep(1);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    // step === 1 && dispatch(image());
    window.onbeforeunload = function() {
      window.scrollTo(0, 0);
    };
    scroll("scroll");

    getIPData();
    if (
      IPData?.IP_Address &&
      IPData.IP_Address !== "" &&
      IPData.completedStep === 1
    ) {
      addIPData();
    }
    // eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    if (
      IPData?.IP_Address &&
      IPData.IP_Address !== "" &&
      IPData.completedStep > 1
    ) {
      UpdateIPData();
    }

    if (step === 1) {
      setPdata([]);
      setAlldata([]);
    }

    // eslint-disable-next-line
  }, [step, IPData.stepName]);

  useEffect(() => {
    if (slug) {
      let seoSlug = slug.replace(/-/g, " ");
      console.log(slug, "check291");
      getCategoryBySlug(seoSlug);
    }
    setIPData({ ...IPData, completedStep: 0 });
    // eslint-disable-next-line
  }, [slug]);

  if (history.location.pathname.includes("size")) {
    return <></>;
  }
  return (
    <div ref={newRef}>
      <Helmet>
        <title>
          {category ? category?.title : slug ? slug : "Wholesale Magnets NZ"}
        </title>
        <meta name="robots" content="max-image-preview:large" />
        <meta
          name="description"
          content={category ? category?.metaDescription : ""}
        ></meta>
        <meta name="keywords" content={category ? category?.seoKey : ""}></meta>
        <link rel="canonical" href={category ? category?.canonical : ""}></link>
      </Helmet>
      {step === 1 ? (
        <>
          <div>
            {history.location.pathname.includes(`/${slug}`) &&
            !history.location.pathname.includes("/size") ? (
              <div className="isCatNameTag">
                <h4 className="p-title">{slug?.replace(/-/g, " ")}</h4>
              </div>
            ) : null}

            <div className="title">
              <h3>Get An Instant Price By SMS Now</h3>
              <h4>
                What size{" "}
                {!history.location.pathname.includes("/printing-products")
                  ? "fridge magnet"
                  : "printing product"}{" "}
                are you looking for ? <span> Click Below</span>
              </h4>
            </div>
            {/* {console.log(
              "check 259",
              history.location.pathname,
              history.location.pathname.split("/").length,
              history.location.pathname.split("/"),
              history.location.pathname.split("/")[
                history.location.pathname.split("/").length - 1
              ],
              history.location.pathname.split("/")[
                history.location.pathname.split("/").length - 2
              ],
              history.location.pathname.split("/")[
                history.location.pathname.split("/").length - 3
              ]
            )} */}
            {console.log(
              "check 275",
              history.location.pathname.split("/")[1] === "",
              "  ",
              history.location.pathname.split("/")[
                history.location.pathname.split("/").length - 1
              ] === "printing-products",
              "   ",
              history.location.pathname.split("/")[
                history.location.pathname.split("/").length - 2
              ] === "/",
              "  ",
              !history.location.pathname.includes(`/${slug}/${brand}`, " ")
            )}

            <div className="row mb-3 mycard">
              {console.log(product, "check 260")}
              {// history.location.pathname.includes(`/home`) ||
              history.location.pathname.split("/")[1] === "" ? (
                // history.location.pathname.includes(`/fridge_magnets`)
                <>
                  {product.map((val, ind) => {
                    return val.parent_category_name === "Fridge Magnets" ? (
                      <div
                        key={"a" + ind}
                        className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                      >
                        <div
                          className="card "
                          onClick={() => {
                            setIPData({
                              ...IPData,
                              completedStep: 0 + 1,
                              stepName: "Product Selected",
                              product_id: val.id,
                              category_id: val.category_id
                            });
                            setStep(step + 1);
                            setPdata({
                              size: val.size,
                              pname: val.product_name,
                              pquantity: val.product_quantity,
                              category: val.name,
                              description: val.description,
                              SKU: val.SKU
                            });
                            setAlldata({
                              ...alldata,
                              cant_find_your_size: "false",
                              category: val.name,
                              size: val.size
                            });

                            // dispatch({ type: "image" });
                            // dispatch(image(val?.product_image));
                            // dispatch(brandimage(val.brandimg));
                          }}
                          style={{
                            cursor: "pointer"
                          }}
                        >
                          <div className="">
                            <img
                              className="card-img-top"
                              src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.product_image}`}
                              alt="Card cap"
                              width="100%"
                            />
                          </div>
                          <div className="prdsize">
                            <p>{val.size} </p>
                          </div>
                          <div className="card-body  text-center img_txt">
                            <h5 className="card-title">{val.product_name}</h5>
                            <h5>{val.parent_category_name}</h5>
                          </div>
                        </div>
                      </div>
                    ) : null;
                  })}
                  <div>
                    <p
                      style={{
                        marginLeft: "15px",
                        fontWeight: 600,
                        textTransform: "uppercase",
                        fontSize: "17px"
                      }}
                    >
                      CAN’T FIND YOUR SIZE ?
                      <Link
                        to="/categories/size"
                        style={{
                          marginLeft: "3px",
                          textDecoration: "underline"
                        }}
                        className="color-secondary"
                      >
                        Click here
                      </Link>
                    </p>
                  </div>
                </>
              ) : history.location.pathname.split("/")[
                  history.location.pathname.split("/").length - 1
                ] === "printing-products" ? (
                <>
                  {product.map((val, ind) => {
                    return val.parent_category_name === "Printing Products" ? (
                      <div
                        className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                        key={"b" + ind}
                      >
                        <div
                          className="card "
                          onClick={() => {
                            setIPData({
                              ...IPData,
                              stepName: "Product Selected",
                              completedStep: 0 + 1,
                              product_id: val.id,
                              category_id: val.category_id
                            });
                            val.name === "ENVELOPES"
                              ? setStep(1 + 0.5)
                              : val.name === "FLYERS"
                              ? setStep(1 + 0.6)
                              : setStep(step + 1);

                            setPdata({
                              size: val.size,
                              pname: val.product_name,
                              pquantity: val.product_quantity,
                              category: val.name,
                              description: val.description,
                              SKU: val.SKU
                            });
                            setAlldata({
                              ...alldata,
                              cant_find_your_size: "false",
                              category: val.name,
                              size: val.size
                            });
                            // dispatch({ type: "image" });
                            // dispatch(image(val?.product_image));
                          }}
                          style={{
                            cursor: "pointer"
                          }}
                        >
                          <div className="">
                            <img
                              className="card-img-top"
                              src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.product_image}`}
                              alt="Card cap"
                              width="100%"
                            />
                          </div>
                          <div className="prdsize">
                            <p>{val.size}</p>
                          </div>
                          <div className="card-body  text-center img_txt">
                            <h5 className="card-title">{val.product_name}</h5>
                            <h5>{val.parent_category_name}</h5>
                          </div>
                        </div>
                      </div>
                    ) : null;
                  })}
                  <div className="col-12 printingtip">
                    <Link to="/contact">
                      Can't find what you're looking for?
                    </Link>
                  </div>
                </>
              ) : history.location.pathname.split("/")[
                  history.location.pathname.split("/").length - 2
                ] === "/" ? (
                product.map((val, ind) => {
                  return val.parent_category_name === "Fridge Magnets" ? (
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                      key={"c" + ind}
                    >
                      <div
                        className="card "
                        onClick={() => {
                          setIPData({
                            ...IPData,
                            stepName: "Product Selected",
                            completedStep: 0 + 1,
                            product_id: val.id,
                            category_id: val.category_id
                          });

                          val.name === "ENVELOPES"
                            ? setStep(1 + 0.5)
                            : val.name === "FLYERS"
                            ? setStep(1 + 0.6)
                            : setStep(step + 1);
                          setPdata({
                            size: val.size,
                            pname: val.product_name,
                            pquantity: val.product_quantity,
                            category: val.name,
                            description: val.description,
                            SKU: val.SKU
                          });
                          setAlldata({
                            ...alldata,
                            cant_find_your_size: "false",
                            category: val.name,
                            size: val.size
                          });
                          // dispatch(image(val?.product_image));
                        }}
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        <div className="">
                          <img
                            className="card-img-top"
                            src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.product_image}`}
                            alt="Card cap"
                            width="100%"
                          />
                        </div>
                        <div className="prdsize">
                          <p>{val.size}</p>
                        </div>
                        <div className="card-body  text-center img_txt">
                          <h5 className="card-title">{val.product_name}</h5>
                          <h5>{val.parent_category_name}</h5>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {!history.location.pathname.includes(
                        `/${slug}/${brand}`
                      ) ? (
                        <>
                          {product.map((val, ind) => {
                            return (
                              // val.show_on_home_page === 1 ?
                              <>
                                <div
                                  className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                                  key={ind}
                                >
                                  <div
                                    className="card "
                                    onClick={() => {
                                      val.name === "ENVELOPES"
                                        ? setStep(1 + 0.5)
                                        : val.name === "FLYERS"
                                        ? setStep(1 + 0.6)
                                        : setStep(step + 1);

                                      setIPData({
                                        ...IPData,
                                        stepName: "Product Selected",
                                        completedStep: 0 + 1,
                                        product_id: val.id,
                                        category_id: val.category_id
                                      });

                                      // setStep(step + 1);
                                      setPdata({
                                        size: val.size,
                                        pname: val.product_name,
                                        pquantity: val.product_quantity,
                                        category: val.name,
                                        description: val.description,
                                        SKU: val.SKU
                                      });
                                      setAlldata({
                                        ...alldata,
                                        cant_find_your_size: "false",
                                        category: val.name,
                                        size: val.size
                                      });

                                      // dispatch({ type: "image" });
                                      // dispatch(image(val?.product_image));
                                    }}
                                    style={{
                                      cursor: "pointer"
                                    }}
                                  >
                                    <div className="">
                                      <img
                                        className="card-img-top"
                                        src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.product_image}`}
                                        alt="Card cap"
                                        width="100%"
                                      />
                                    </div>
                                    <div className="prdsize">
                                      <p>{val.size}</p>
                                    </div>
                                    <div className="card-body  text-center img_txt">
                                      <h5 className="card-title">
                                        {val.product_name}
                                      </h5>
                                      <h5>{val.parent_category_name}</h5>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                            // : null;
                          })}
                        </>
                      ) : (
                        <>
                          {productBrand.map((val, ind) => {
                            return (
                              <>
                                <div
                                  className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                                  key={ind}
                                >
                                  <div
                                    className="card"
                                    onClick={() => {
                                      val.name === "ENVELOPES"
                                        ? setStep(1 + 0.5)
                                        : val.name === "FLYERS"
                                        ? setStep(1 + 0.6)
                                        : setStep(step + 1);
                                      setIPData({
                                        ...IPData,
                                        stepName: "Product Selected",
                                        completedStep: 0 + 1,
                                        product_id: val.id,
                                        category_id: val.category_id
                                      });

                                      // setStep(step + 1);
                                      setPdata({
                                        size: val.size,
                                        pname: val.product_name,
                                        pquantity: val.product_quantity,
                                        category: val.name,
                                        description: val.description
                                      });
                                      setAlldata({
                                        ...alldata,
                                        cant_find_your_size: "false",
                                        category: val.name,
                                        size: val.size
                                      });

                                      // dispatch(image(val?.brandimg));
                                    }}
                                    style={{
                                      cursor: "pointer"
                                    }}
                                  >
                                    <img
                                      className="card-img-top"
                                      src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.brandimg}`}
                                      alt="Card cap"
                                      width="280px"
                                      height="90%"
                                    />
                                    <div className="prdsize">
                                      <p>{val.size}</p>
                                    </div>
                                    <div className="card-body  text-center img_txt">
                                      <h5 className="card-title">
                                        {val.product_name}
                                      </h5>
                                      <h5>{val.parent_category_name}</h5>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  {!history.location.pathname.includes(`/${slug}/${brand}`) ? (
                    <>
                      {product.map((val, ind) => {
                        return (
                          //val.show_on_home_page === 1 ?
                          <div
                            className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                            key={"d" + ind}
                          >
                            <div
                              className="card "
                              onClick={() => {
                                setIPData({
                                  ...IPData,
                                  stepName: "Product Selected",
                                  completedStep: 0 + 1,
                                  product_id: val.id,
                                  category_id: val.category_id
                                });

                                val.name === "ENVELOPES"
                                  ? setStep(1 + 0.5)
                                  : val.name === "FLYERS"
                                  ? setStep(1 + 0.6)
                                  : setStep(step + 1);

                                // setStep(step + 1);
                                setPdata({
                                  size: val.size,
                                  pname: val.product_name,
                                  pid: val.id,
                                  pquantity: val.product_quantity,
                                  category: val.name,
                                  description: val.description,
                                  SKU: val.SKU
                                });
                                setAlldata({
                                  ...alldata,
                                  cant_find_your_size: "false",
                                  category: val.name,
                                  size: val.size
                                });

                                // dispatch({ type: "image" });
                                // dispatch(image(val?.product_image));
                              }}
                              style={{
                                cursor: "pointer"
                              }}
                            >
                              <div className="">
                                <img
                                  className="card-img-top"
                                  src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.product_image}`}
                                  alt="Card cap"
                                  width="100%"
                                />
                              </div>
                              <div className="prdsize">
                                <p>{val.size}</p>
                              </div>
                              <div className="card-body  text-center img_txt">
                                <h5 className="card-title">
                                  {val.product_name}
                                </h5>
                                <h5>{val.parent_category_name}</h5>
                              </div>
                            </div>
                          </div>
                        );
                        // : null;
                      })}
                    </>
                  ) : (
                    <>
                      {productBrand.map((val, ind) => {
                        return (
                          <>
                            <div
                              className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
                              key={ind}
                            >
                              <div
                                className="card"
                                onClick={() => {
                                  setIPData({
                                    ...IPData,
                                    stepName: "Product Selected",
                                    completedStep: 0 + 1,
                                    product_id: val.id,
                                    category_id: val.category_id
                                  });

                                  val.name === "ENVELOPES"
                                    ? setStep(1 + 0.5)
                                    : val.name === "FLYERS"
                                    ? setStep(1 + 0.6)
                                    : setStep(step + 1);

                                  // setStep(step + 1);
                                  setPdata({
                                    size: val.size,
                                    pname: val.product_name,
                                    pquantity: val.product_quantity,
                                    category: val.name,
                                    description: val.description
                                  });
                                  setAlldata({
                                    ...alldata,
                                    cant_find_your_size: "false",
                                    category: val.name,
                                    size: val.size
                                  });
                                  // dispatch(image(val?.brandimg));
                                  // dispatch({ type: "image" });
                                  // image(val.product_image);
                                }}
                                style={{
                                  cursor: "pointer"
                                }}
                              >
                                <img
                                  className="card-img-top"
                                  src={`${process.env.REACT_APP_BACKEND_UPLOAD_PATH}/${val?.brandimg}`}
                                  alt="Card cap"
                                  width="280px"
                                  height="90%"
                                />
                                <div className="prdsize">
                                  <p>{val.size}</p>
                                </div>
                                <div className="card-body  text-center img_txt">
                                  <h5 className="card-title">
                                    {val.product_name}
                                  </h5>
                                  <h5>{val.parent_category_name}</h5>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {history.location.pathname.includes(`${slug}`) &&
          !history.location.pathname.includes("size") ? (
            <div className="">
              <span className="cateogoryDescp">
                <div
                  dangerouslySetInnerHTML={{
                    __html: product[0]?.description
                  }}
                  className="notes-text"
                />
              </span>
            </div>
          ) : null}

          {(!history.location.pathname.includes("/size") &&
            !history.location.pathname.split("/")[
              history.location.pathname.split("/").length - 1
            ] === "printing-products") ||
          !history.location.pathname.split("/")[
            history.location.pathname.split("/").length - 1
          ] === "" ? (
            <div className="row ">
              <div className="col-12">
                <h4
                  style={{
                    color: "#444444",
                    fontWeight: "600",
                    textTransform: "uppercase",
                    fontSize: "17px",
                    margin: "35px 0px 53px 0px"
                  }}
                >
                  Can't find your size ?{" "}
                  <Link
                    to="/categories/size"
                    style={{
                      textDecoration: "underline"
                    }}
                    className="color-secondary"
                  >
                    Click here
                  </Link>
                </h4>
              </div>
            </div>
          ) : null}
        </>
      ) : step === 1.5 ? (
        <Color
          data={{
            setStep,
            step,
            product,
            alldata,
            setAlldata,
            pdata,
            props,
            IPData,
            setIPData
          }}
        />
      ) : step === 1.6 ? (
        <Papers
          data={{
            setStep,
            step,
            product,
            alldata,
            setAlldata,
            pdata,
            props,
            IPData,
            setIPData
          }}
        />
      ) : step === 2 ? (
        <Detail
          data={{
            setStep,
            step,
            product,
            alldata,
            setAlldata,
            pdata,
            IPData,
            setIPData
          }}
        />
      ) : step === 3 ? (
        <Artwork
          data={{
            setStep,
            step,
            alldata,
            setAlldata,
            pdata,
            IPData,
            setIPData
          }}
        />
      ) : step === 3.5 ? (
        <Markers
          data={{
            setStep,
            step,
            alldata,
            setAlldata,
            pdata,
            IPData,
            setIPData
          }}
        />
      ) : (
        // <GoogleReCaptchaProvider
        //   reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        //   scriptProps={{
        //     async: false,
        //     defer: false,
        //     appendTo: "head",
        //     nonce: undefined
        //   }}
        // >
        <Userdetail
          data={{
            setStep,
            step,
            alldata,
            setAlldata,
            pdata,
            IPData,
            setIPData
          }}
        />
        // </GoogleReCaptchaProvider>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken, //!= null? state.auth.accessToken : state.auth.token,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};
// const mapDispatchToProps = () => {};

export default compose(
  withRouter,
  // enhancer,
  connect(
    mapStateToProps,
    { success, error, fetching, scroll }
    // mapDispatchToProps
  )
)(Content);
