import { toast } from "react-toastify";
import { GetGroupStatusHirarchy } from "services/groupsServices";

const navigationAction = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  CLOSE: "CLOSE",
  FETCHING: "FETCHING",
  SCROLL: "SCROLL",

  success: messages => {
    messages !== "" &&
      toast.success(messages, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    return {
      type: navigationAction.SUCCESS,
      resType: "success",
      message: messages,
      show: true,
      isFetching: false
    };
  },
  error: messages => {
    messages !== "" &&
      toast.error(messages, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    return {
      type: navigationAction.ERROR,
      resType: "error",
      message: messages,
      show: true,
      isFetching: false
    };
  },
  close: () => {
    return {
      type: navigationAction.CLOSE,
      show: false,
      isFetching: false
    };
  },
  fetching: () => ({
    type: navigationAction.FETCHING,
    isFetching: true
  }),
  scroll: value => {
    return {
      type: navigationAction.SCROLL,
      step_scroll: value
    };
  }
};
// export const

export default navigationAction;
