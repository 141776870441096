import React from "react";

const DisplayEditorContent = ({ editorData, isshortNews }) => {
  const renderBlock = block => {
    switch (block.type) {
      case "paragraph": {
        return isshortNews ? (
          <div>
            {block?.data?.text?.length > 280 ? (
              <p
                className="article-item__excerpt"
                dangerouslySetInnerHTML={{
                  __html:
                    block.data.text.slice(0, 280) +
                    " <strong>...Read more</strong> "
                }}
              ></p>
            ) : (
              <p
                className="article-item__excerpt"
                dangerouslySetInnerHTML={{
                  __html: block.data.text
                }}
              ></p>
            )}
          </div>
        ) : (
          <div>
            <p
              className="article-item__excerpt"
              dangerouslySetInnerHTML={{
                __html: block.data.text
              }}
            ></p>
          </div>
        );
      }

      case "header":
        return isshortNews ? (
          <div>
            {block?.data?.text?.length > 280 ? (
              <h2
                className="my-2 "
                dangerouslySetInnerHTML={{
                  __html: block.data.text.slice(0, 280)
                }}
              ></h2>
            ) : (
              <h2
                className="my-2 "
                dangerouslySetInnerHTML={{
                  __html: block.data.text
                }}
              ></h2>
            )}
          </div>
        ) : (
          <div>
            <h2
              className="my-2 "
              dangerouslySetInnerHTML={{ __html: block.data.text }}
            ></h2>
          </div>
        );
      case "list":
        return (
          <ul>
            {block?.data?.items?.map((item, index) => (
              <li
                key={index}
                className="my-2 "
                dangerouslySetInnerHTML={{ __html: item }}
              ></li>
            ))}
          </ul>
        );
      case "linkTool":
        return (
          <a href={block?.data?.link} className="my-2 ">
            {block?.data?.meta?.title}
          </a>
        );
      case "image":
        return <img className="my-2 " src={block?.data?.file?.url}></img>;
      case "table":
        return (
          <table className="my-2">
            <tbody>
              {block?.data?.content?.map((row, rowIndex) => (
                <tr className="w-100" key={rowIndex}>
                  {row?.map((cell, cellIndex) => (
                    <td key={cellIndex} className="px-2 py-1 border">
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      // Add cases for other block types as needed
      default:
        return null;
    }
  };

  return (
    <div className="editor-content">
      {editorData?.map((block, index) => (
        <div key={index}>{renderBlock(block)}</div>
      ))}
    </div>
  );
};

export default DisplayEditorContent;
