const currentRouteAction = {
  CURRENT_ROUTE: "CURRENT_ROUTE",

  currentRoute: data => {
    console.log("image", data);
    return {
      type: currentRouteAction.CURRENT_ROUTE,
      payload: data
    };
  }
};

export default currentRouteAction;
