import { api, handleResponse, handleError } from "./apiServices";

export const getGallery = (token, data) =>
  api(token)
    .post("/gallery/list", data)
    .then(handleResponse)
    .catch(handleError);

export const getGallerys = (token, data) =>
  api(token)
    .get("/gallerys/list")
    .then(handleResponse)
    .catch(handleError);
