import Layout from "layouts/DashboardLayout.js";
import { Error400, Error500 } from "./../views/pages/index";

const indexRoutes = [
  // { path: "/pricing/style1", component: PricingStyle1 },
  // { path: "/pricing/style2", component: PricingStyle2 },
  { path: "/error400", component: Error400 },
  { path: "/error500", component: Error500 },
  // { path: "/news", component: NewsLayout },
  // { path: "/about-us", component: NewsLayout },
  // { path: "/contact-us", component: NewsLayout },
  // { path: "/artworks", component: NewsLayout },
  // { path: "/faq", component: NewsLayout },

  { path: "/", component: Layout }
];

export default indexRoutes;
