import { api, handleResponse, handleError } from "./apiServices";

export const getIPAdd = () =>
  api()
    .get("/IPAdd/list")
    .then(handleResponse)
    .catch(handleError);

export const addIPAdd = data =>
  api()
    .post("/IPAdd/add", data)
    .then(handleResponse)
    .catch(handleError);

export const updateIPAdd = data =>
  api()
    .put("/IPAdd/update", data)
    .then(handleResponse)
    .catch(handleError);

export const deleteIPAdd = data =>
  api()
    .delete("/IPAdd/delete", { data: data })
    .then(handleResponse)
    .catch(handleError);
