import React, { useEffect, useState } from "react";
import Newsdetail from "./newsdetail";
import { getNewsById } from "services/newsServices";
import {
  useParams,
  withRouter
} from "react-router-dom/cjs/react-router-dom.min";
import { compose } from "redux";
import { connect } from "react-redux";
import navigationAction from "redux/navigation/actions";
const { success, error } = navigationAction;

function NewsBySlug(props) {
  let [newsData, setNewsData] = useState({});
  let { slug } = useParams();
  slug = decodeURIComponent(slug);
  slug = slug.split("_").join(" ");
  let getNewsData = async () => {
    await getNewsById(slug).then(res => {
      if (res.success) {
        success(res.message);
        setNewsData(res.data);
      }
    });
  };
  useEffect(() => {
    getNewsData();
  }, [slug]);
  return (
    <div>
      <Newsdetail isshortNews={false} data={newsData} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { success, error })
)(NewsBySlug);
