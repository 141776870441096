import productimageAction from "./actions";

// const initState = {
//   image_src: "",
// };

// export default function rootReducer(state = initState, action) {
//   switch (action.type) {
//     case productimageAction.IMAGE:
//       return {
//         ...state,
//         image_src: action.payload,
//       };
//     default:
//       return state;
//   }
// }

const initState = [
  {
    tabName: "",
    image_src: "",
    isPrinting: false
  }
];
export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case productimageAction.IMAGE:
      state.push(action.payload);
      const updatedState = state.filter(
        e => e.tabName !== action.payload.tabName
      );
      return updatedState.length > 0 && Object.keys(action?.payload).length == 3
        ? [...updatedState, { isPrinting: false, ...action?.payload }]
        : updatedState;

    case productimageAction.RESET:
      return [
        {
          tabName: "",
          image_src: "",
          isPrinting: false
        }
      ];
    default:
      return state;
  }
}
