import React, { useEffect, useRef, useState } from "react";
import enhancer from "./enhancer/contentenhancer";
import NavigationActions from "redux/navigation/actions";
import { useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { addInquiry, reccaptchaToken } from "services/inquiryServices";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getCategories } from "services/categoryServices";
import {
  getMagSmsServer,
  getPrintSmsServer
} from "services/thirdpartyapiServices";
import InquiryActions from "redux/InquiryData/actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const { setAllData, setIpData, setPData, setStep } = InquiryActions;
const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function Userdetail(props) {
  const {
    token,
    fetching,
    values,
    handleChange,
    handleSubmit,
    isValid,
    setValues,
    handleBlur,
    errors,
    touched,
    submitCount,
    inquiry
  } = props;
  const dispatch = useDispatch();
  let { slug } = useParams();
  const { step, pData, ipData, allData } = inquiry;
  const scrollRef = useRef();
  const [parentId, setParentId] = useState();
  const [successResponse, setSuccessResponse] = useState(false);
  const history = useHistory();
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  // for CAPTCHA
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSumitForm = e => {
    // useCallback(
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      console.log(executeRecaptcha, "executeRecaptcha");
      if (!executeRecaptcha) {
        console.log("here here here here");

        console.log("Execute recaptcha not yet available");
        return;
      }
      console.log(token, "token", "value");
      // executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
      //   reccaptchaToken(token, { gReCaptchaToken }).then((data) => {
      //     if (data.data.success === true) {
      //       onsubmitdata();
      //       // res.data.success()
      //       // error(res.data.message)
      //     } else {
      //       // error(res.data.message)
      //       error(data.message);
      //     }
      //   });
      // });
      if (!history.location.pathname.includes("/size")) {
        dispatch(
          setIpData({
            ...ipData,
            stepName: "Inquiry visited",
            completedStep: ipData.completedStep + 1
          })
        );
      }
    }

    onsubmitdata();
  };

  const get_Parent_Categories = async () => {
    await getCategories().then(data => {
      console.log("data check 85", data, allData);

      if (data?.success) {
        const parent_id = data.data.find(
          data => data?.name === allData?.category
        )?.parent_id;
        setParentId(parent_id);
      } else {
        console.log("error");
      }
    });
  };

  const onsubmitdata = async () => {
    // e.preventDefault();
    handleSubmit();

    let data = {
      ...allData,
      name: values.name,
      company: values.company,
      mobile: values.mobile_no,
      postcode: values.delivery_postcode,
      email: values.email,
      color: allData.category === "FLYERS" ? undefined : allData.color,
      paper_choice:
        allData.category === "ENVELOPES" ? undefined : allData.paper_choice
    };

    if (isValid) {
      fetching();
      if (parentId) {
        console.log("here check 120");
        await InquiryURL(pData?.product_name ?? pData?.pname, data, pData?.SKU);
      }
      console.log("inquiry before");
      await addInquiry(token, data).then(data => {
        console.log("inquiry after");
        if (data.success) {
          setSuccessResponse(true);

          dispatch(setIpData({ ...ipData, stepName: "Inquiry Added" }));
        } else {
          error(data.message);
        }
      });
    }
  };

  const InquiryURL = async (category, data, sku) => {
    let categorySizeEncoded = "";
    category = category ? category?.toLowerCase() : "";
    if (category.includes("standard") !== false || category.includes("dl")) {
      let text = "Standard DL";
      categorySizeEncoded = text.replaceAll(" ", "+");
    } else if (category.includes("business card") !== false) {
      let text = "Business Card";
      categorySizeEncoded = text.replaceAll(" ", "+");
    } else if (category.includes("house shape") !== false) {
      let text = "House Shape";
      categorySizeEncoded = text.replaceAll(" ", "+");
    } else if (category.includes("custom shaped") !== false) {
      let text = "Other Shape";
      categorySizeEncoded = text.replaceAll(" ", "+");
    } else if (category.includes("a5") !== false) {
      let text = "A5";
      categorySizeEncoded = text.replaceAll(" ", "+");
    } else if (category.includes("a6") !== false) {
      let text = "A6";
      categorySizeEncoded = text.replaceAll(" ", "+");
    } else if (category.includes("a7") !== false) {
      let text = "A7";
      categorySizeEncoded = text.replaceAll(" ", "+");
    } else if (category.includes("with marker") !== false) {
      let text = "A5";
      //$categorySizeEncoded = 'A5 With Marker'
      categorySizeEncoded = text.replaceAll(" ", "+");
    } else {
      let text = "Other Shape";
      categorySizeEncoded = text.replaceAll(" ", "+");
    }
    // console.log(categorySizeEncoded,"categorySizeEncoded")
    for (const val in data) {
      if (val !== undefined) {
        val.trim().replaceAll(" ", "+");
        // data = {...data,data}
      }
    }

    data = {
      ...data,
      name: data.name.trim().replaceAll(" ", "+"),
      company: data.company.trim().replaceAll(" ", "+")
    };

    category = category.trim().replaceAll(" ", "+");
    if (parentId === 1) {
      let payload = {
        ...data,
        categorySizeEncoded: categorySizeEncoded,
        category: category
      };

      await getMagSmsServer(payload).then(res => {
        if (res.data && res.data === "match") {
          setSuccessResponse(true);
        }
      });
    }

    if (parentId === 2) {
      let payload = {
        ...data,
        sku: sku,
        category: category
      };

      await getPrintSmsServer(payload).then(res => {
        // console.log(res.data, "data");
        if (res.data && res.data === "match") {
          setSuccessResponse(true);
        }
      });
    }
  };
  useEffect(() => {
    get_Parent_Categories();
  }, []);

  useEffect(() => {
    if (successResponse) {
      scrollRef.current.scrollIntoView();
    }
  }, [successResponse]);

  // console.log(successResponse, "successresponse");
  return (
    <>
      <div className="row wanting" ref={scrollRef}>
        {" "}
        <div className="col-lg-12 col-md-12 col-sm-12 px-0">
          {!history.location.pathname.includes("/size") ? (
            <>
              <div>
                <div className="isCatNameTag">
                  <h4 className="p-title">{slug?.replace(/-/g, " ")}</h4>
                </div>
                <h5>
                  {" "}
                  {/*className="d-flex justify-content-between cat_width */}
                  <span className="mr-2">{pData.product_name}</span>
                  {pData.size}
                  {/* <span className="mr-2">{pData.size}</span> */}
                </h5>
                <h3 className="sms-title">Get An Instant Price By SMS Now</h3>
              </div>
            </>
          ) : (
            <>
              {/* <div className="col-12"> */}
              <h3 className="sms-title1 pl-3">
                Looking for something different ?
              </h3>
              {/* </div> */}
            </>
          )}
          {successResponse ? (
            <>
              <div>
                <h1>Thank you for your enquiry.</h1>
                <h5>You should receive a price by SMS and Email shortly.</h5>
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <div className="my-2">
                  <h4 className="mb-4 y-title">Your Details</h4>
                  <form>
                    <label className="d-block mt-2 mb-0 label">
                      Name
                      {/* <span className='error-msg'>*</span>: */}
                    </label>

                    <input
                      id="name"
                      type="text"
                      name="name"
                      className="form-control user_form_width"
                      // onChange={(e) => onhandlechange(e)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.name}
                    />
                    <Error field="name" />
                    <label className="d-block mt-2 mb-0 label">
                      Company
                      {/* <span className='error-msg'>*</span>: */}
                    </label>

                    <input
                      id="company"
                      type="text"
                      name="company"
                      className="form-control user_form_width"
                      // onChange={(e) => onhandlechange(e)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.company}
                    />
                    <Error field="company" />
                    <label className="d-block mt-2 mb-0 label">
                      Mobile(for SMS Quote)
                      {/* <span className='error-msg'>*</span>: */}
                    </label>

                    <input
                      id="mobile_no"
                      type="text"
                      name="mobile_no"
                      className="form-control user_form_width"
                      onBlur={handleBlur}
                      onChange={e => {
                        // const re = /^[0-9]*$/;
                        // if (re.test(e.target.value)) {
                        setValues({
                          ...values,
                          mobile_no: e.target.value
                        });
                        // }
                      }}
                      value={values?.mobile_no}
                    />
                    <Error field="mobile_no" />
                    {/* <label className="d-block mt-2 mb-0 label">
                      Delivery Postcode
                      <span className='error-msg'>*</span>:
                    </label>

                    <input
                      id="delivery_postcode"
                      type="text"
                      name="delivery_postcode"
                      className="form-control user_form_width "
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.delivery_postcode}
                    />
                    <Error field="delivery_postcode" /> */}
                    <label className="d-block mt-2 mb-0 label">
                      Email
                      {/* <span className='error-msg'>*</span>: */}
                    </label>

                    <input
                      id="email"
                      type="text"
                      name="email"
                      className="form-control user_form_width"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.email}
                    />
                    <Error field="email" />
                  </form>
                </div>
              </div>
              <div className="col user_form_btn mb-3">
                <button
                  type="button"
                  className="btn btn_base_style my-2 mx-1"
                  onClick={() => {
                    allData.category === "SHOPPING LIST TO DO LISTS"
                      ? dispatch(setStep(step - 0.5))
                      : dispatch(setStep(step - 1));

                    if (!history.location.pathname.includes("/size")) {
                      dispatch(
                        setIpData({
                          ...ipData,
                          completedStep: ipData.completedStep - 1
                        })
                      );
                    }
                  }}
                >
                  Previous
                </button>
                <button
                  type="button"
                  className="btn btn_base_style my-2"
                  onClick={e => {
                    handleSumitForm(e);
                  }}
                >
                  Get Price
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  console.log("check state 168", state);
  return {
    ...state.themeChanger,
    token: state.auth.accessToken, //!= null? state.auth.accessToken : state.auth.token,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    inquiry: state.inquiry
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(Userdetail);
