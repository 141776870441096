import React from "react";
import { history } from "redux/store";
import InquiryActions from "redux/InquiryData/actions";
import navigationAction from "redux/navigation/actions";
import { compose } from "redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect, useDispatch } from "react-redux";
const { success, error } = navigationAction;
const { setAllData, setIpData, setPData, setStep } = InquiryActions;

function Markers(props) {
  const { inquiry } = props;
  const { step, pData, ipData, allData } = inquiry;
  const dispatch = useDispatch();

  return (
    <>
      <div className="row wanting">
        <div className="col-lg-12 col-md-10 col-sm-12">
          <h3 className="sms-title1">Looking for something different ?</h3>
          <div className="my-2">
            <h4 className="art">
              Would you like whiteboard markers included with each magnet ?
            </h4>
            <div className="label" style={{ cursor: "pointer" }}>
              <input
                id="artwork1"
                type="radio"
                checked={
                  allData?.marker !== "no" || allData?.marker === undefined
                    ? true
                    : false
                }
                name="r1"
                className="mr-1"
                onChange={e => {
                  dispatch(setAllData({ ...allData, marker: "yes" }));
                }}
              />
              <label htmlFor="artwork1"> Yes</label>
            </div>
            <div className="label">
              <input
                id="artwork2"
                type="radio"
                name="r1"
                // checked={allData?.marker === "yes" ? false : true}
                className="mr-1"
                onChange={e => {
                  dispatch(setAllData({ ...allData, marker: "no" }));
                }}
              />
              <label htmlFor="artwork2" style={{ cursor: "pointer" }}>
                {" "}
                No
              </label>
            </div>
          </div>
          <div className="col-12 col-lg-6 text-center mb-3">
            <button
              type="button"
              className="btn btn_base_style my-2"
              onClick={() => {
                allData.category === "SHOPPING LIST TO DO LISTS"
                  ? dispatch(setStep(step - 0.5))
                  : dispatch(setStep(step - 1));
                // setStep(step - 1);
                if (!history.location.pathname.includes("/size")) {
                  dispatch(
                    setIpData({
                      ...ipData,
                      completedStep: ipData.completedStep - 1
                    })
                  );
                }
              }}
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn_base_style my-2 mx-1"
              onClick={() => {
                allData.category === "SHOPPING LIST TO DO LISTS"
                  ? dispatch(setStep(step + 0.5))
                  : dispatch(setStep(step + 1));
                if (!history.location.pathname.includes("/size")) {
                  dispatch(
                    setIpData({
                      ...ipData,
                      stepName: "Marker Selected",
                      completedStep: ipData.completedStep + 1
                    })
                  );
                }

                if (allData.marker === undefined) {
                  dispatch(setAllData({ ...allData, marker: "yes" }));
                } else {
                  dispatch(setAllData({ ...allData }));
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => {
  console.log("check state 168", state);
  return {
    ...state.themeChanger,
    token: state.auth.accessToken, //!= null? state.auth.accessToken : state.auth.token,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    inquiry: state.inquiry
  };
};
// const mapDispatchToProps = () => {};

export default compose(
  withRouter,
  // enhancer,
  connect(
    mapStateToProps,
    { success, error }
    // mapDispatchToProps
  )
)(Markers);
