import navigationAction from "./actions";

const initState = {
  show: false,
  message: "",
  type: "",
  isFetching: false,
  step_scroll: ""
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case navigationAction.SUCCESS:
      return {
        ...state,
        show: action.show,
        message: action.message,
        resType: action.resType,
        isFetching: action.isFetching
      };

    case navigationAction.ERROR:
      return {
        ...state,
        show: action.show,
        message: action.message,
        resType: action.resType,
        isFetching: action.isFetching
      };

    case navigationAction.CLOSE:
      return {
        ...state,
        show: action.show,
        isFetching: action.isFetching
      };

    case navigationAction.FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      };

    case navigationAction.SCROLL:
      return {
        ...state,
        step_scroll: action.step_scroll
      };
    default:
      return state;
  }
}
